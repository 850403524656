.data {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: rgba(229, 231, 235, 1) solid 1px;
  border-collapse: collapse;
}

.dataLabel {
}

.dataItem {
}
