body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* yanked from auth0 */
/* .navbar-light.bg-light .nav-link {
  color: #041433; }

.navbar {
  background-color: #eff1f5;
  padding: 1.375rem 0;
  box-shadow: 0px 1px 2px 0px #dfe3ec; }
  .navbar .router-link-exact-active {
    color: #041433;
    border-bottom: 3px solid #0066f9; }

.navbar-brand {
  margin-right: 60px; }

.navbar-nav .nav-link {
  color: #041433;
  padding: 0 0 0.5rem 0;
  box-sizing: border-box;
  margin: 0 40px 0 0;
  font-weight: 400; }
  .navbar-nav .nav-link:hover {
    color: #000104; }

.navbar-nav .btn {
  min-width: 145px; }

.nav-user-profile {
  border-radius: 50% 50%;
  max-width: 3.785rem;
  height: auto;
  box-shadow: 0px 0px 4px 0px #b3bac7; }

.nav-item.dropdown .dropdown-toggle {
  margin: 0; }

.nav-item.dropdown .dropdown-name {
  font-weight: 600; }

.nav-item.dropdown .router-link-exact-active {
  border: 0; }

.nav-item.dropdown .dropdown-menu {
  box-shadow: 0px 0px 4px 0px #b3bac7; }

.nav-item.dropdown .dropdown-header {
  border-bottom: 1px solid #b3bac7;
  font-size: 1rem;
  font-weight: 600;
  color: #041433; }

.nav-item.dropdown .dropdown-item {
  border-bottom: 1px solid #b3bac7;
  padding: 0.55rem 1.5rem; }
  .nav-item.dropdown .dropdown-item .icon {
    margin-right: 8px;
    vertical-align: middle; }
  .nav-item.dropdown .dropdown-item:first-child:hover {
    background: inherit; }
  .nav-item.dropdown .dropdown-item:last-child {
    border-bottom: 0; }

.navbar {
  padding: 1.375rem 0; }

@media (min-width: 576px) {
  .container {
    max-width: inherit; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

@media (max-width: 767px) {
  .nav-item {
    margin-bottom: 1rem; }
    .nav-item .nav-link {
      padding: 0 0 0 16px; }
      .nav-item .nav-link.router-link-exact-active {
        border-bottom: 0;
        border-left: 3px solid #0066f9;
        padding-left: 13px !important;
        vertical-align: middle; }
  .navbar-brand {
    margin-left: 2.5rem;
    vertical-align: top; }
  .navbar-toggler {
    margin-right: 2.5rem; }
  .navbar-nav {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
    .navbar-nav:first-child {
      margin-top: 1.5em; }
    .navbar-nav:last-child {
      background: white;
      box-shadow: 0 -1px 2px 0 #dfe3ec;
      margin: 1.5em 0 0 0;
      padding: 1.5em 2.5rem; }
      .navbar-nav:last-child li {
        margin-bottom: 1em; }
        .navbar-nav:last-child li .icon {
          margin-right: 1em;
          vertical-align: middle; }
        .navbar-nav:last-child li a {
          vertical-align: middle;
          color: #041433; }
    .navbar-nav .user-info img {
      margin-right: 1em; }
    .navbar-nav .btn-link {
      padding: 0;
      color: #041433;
      min-width: 0px; } }

.logo {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzYgNDEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+PGRlZnM+PGZpbHRlciB4PSItNi45JSIgeT0iLTYuMiUiIHdpZHRoPSIxMTMuOSUiIGhlaWdodD0iMTEyLjUlIiBmaWx0ZXJVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIGlkPSJmaWx0ZXItMSI+PGZlT2Zmc2V0IGR4PSIwIiBkeT0iMSIgaW49IlNvdXJjZUFscGhhIiByZXN1bHQ9InNoYWRvd09mZnNldE91dGVyMSI+PC9mZU9mZnNldD48ZmVDb2xvck1hdHJpeCB2YWx1ZXM9IjAgMCAwIDAgMSAgIDAgMCAwIDAgMSAgIDAgMCAwIDAgMSAgMCAwIDAgMC41IDAiIHR5cGU9Im1hdHJpeCIgaW49InNoYWRvd09mZnNldE91dGVyMSIgcmVzdWx0PSJzaGFkb3dNYXRyaXhPdXRlcjEiPjwvZmVDb2xvck1hdHJpeD48ZmVNZXJnZT48ZmVNZXJnZU5vZGUgaW49InNoYWRvd01hdHJpeE91dGVyMSI+PC9mZU1lcmdlTm9kZT48ZmVNZXJnZU5vZGUgaW49IlNvdXJjZUdyYXBoaWMiPjwvZmVNZXJnZU5vZGU+PC9mZU1lcmdlPjwvZmlsdGVyPjwvZGVmcz48ZyBpZD0iUHJvamVjdC1TYW1wbGUtVUkiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJIb21lLUxvZ2dlZCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTI0Ny4wMDAwMDAsIC0yOC4wMDAwMDApIj48ZyBpZD0iSGVhZGVyIj48ZyBpZD0iTG9nbyIgZmlsdGVyPSJ1cmwoI2ZpbHRlci0xKSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjQ3LjAwMDAwMCwgMjguMDAwMDAwKSI+PHBhdGggZD0iTTMxLjA3NzIzMjQsMCBMMTcuOTk5NTI4NywwIEw0LjkxOTYzMTUyLDAgTDAuODc4MDkwMjU4LDEyLjM1OTU2MjUgQy0xLjU3OTczNzE0LDE5Ljk0OTAyODQgMS4yODcxNzk3OCwyNy45Mzc3NzIxIDcuNDE2OTQyMDksMzIuMzYwNjI0NCBMMTcuOTk3MzM1Miw0MCBMMjguNTgxMDE4NiwzMi4zNTk1NjI1IEMzNS4wNDA5MDQsMjcuNjk1NjU2OCAzNy40NjAzNDUsMTkuNTU5MzA3NiAzNS4xMzQxMjgyLDEyLjM5Nzc5MTIgQzM1LjEyNDI1NzQsMTIuMzY0ODcyIDMxLjA3NzIzMjQsMCAzMS4wNzcyMzI0LDAiIGlkPSJGaWxsLTQiIGZpbGw9IiMwNDE0MzMiPjwvcGF0aD48cG9seWdvbiBpZD0iRmlsbC02IiBmaWxsPSIjRUZGMUY1IiBwb2ludHM9IjE4IDAgMjIuMDEyOTU0NyAxMi42MDQ3OTE4IDM1IDEyLjYwNDc5MTggMjQuNDkyOTgwMiAyMC4zOTUyMDgyIDI4LjUwNzAxOTggMzMgMTggMjUuMjEwNjY4MiA3LjQ5Mjk4MDIyIDMzIDExLjUwNTkzNDkgMjAuMzk1MjA4MiAxIDEyLjYwNDc5MTggMTMuOTg3MDQ1MyAxMi42MDQ3OTE4Ij48L3BvbHlnb24+PC9nPjwvZz48L2c+PC9nPjwvc3ZnPg==");
  width: 2rem;
  height: 2.25rem;
  background-repeat: no-repeat;
  background-size: cover; } */
/* 
footer {
  background: #eff1f5;
  box-shadow: 0px -1px 2px 0px #dfe3ec;
  min-height: 132px;
  text-align: center;
  padding: 32px; }
  footer .logo {
    margin: 0 auto 10px auto; }

.hero {
  max-width: 500px;
  margin: 0 auto; }
  .hero p.lead {
    margin-bottom: 10rem;
    font-size: 1.45rem; }
    .hero p.lead a {
      font-weight: 500; }
  .hero .app-logo {
    max-width: 7.5rem; }

.next-steps {
  padding: 0 32px; }
  .next-steps h2 {
    margin-bottom: 4rem; }
  .next-steps .row {
    margin-bottom: 3rem; }

@media (max-width: 768px) {
  .next-steps .row {
    margin-bottom: 0; }
  .next-steps h6 {
    margin-top: 1.5rem; } }

pre {
  width: 100%; }

.profile-header {
  text-align: center;
  margin-bottom: 3rem; }
  .profile-header .lead {
    font-size: 1.25rem; }
  .profile-header .profile-picture {
    box-shadow: 0px 0px 4px 0px #b3bac7;
    margin-bottom: 1.5rem; }

@media (min-width: 768px) {
  .profile-header {
    text-align: left; }
    .profile-header .profile-picture {
      margin-bottom: inherit; } }

.result-block-container {
  position: relative;
  min-height: 300px; }

.result-block {
  top: -10px;
  position: absolute;
  opacity: 0;
  transition: all 0.3s; }
  .result-block.show {
    opacity: 1;
    top: 0px; }

@media only screen and (min-width: 768px) {
  .result-block {
    min-width: 700px; } }

html {
  font-size: 16px; }

body {
  color: #041433; }

html,
body {
  height: 100%;
  margin: 0;
  padding: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600; }

h6.muted {
  color: #6c757d;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: 1rem; }

a {
  color: #0066f9; }

.btn-primary {
  background-color: #0066f9;
  border: 1px solid #005ce0; }

.btn {
  border-radius: 0.1rem; } */
